import styles from './InstagramFeed.module.scss'
import cx from 'classnames'
// import { useSocialFeed } from '../../../lib/data/use-social-feed'

import Reel from '../../../public/svg/instagram-feed/reel.svg'
import Video from '../../../public/svg/instagram-feed/video.svg'
import Image from '../../Image'

const InstagramFeed = ({ content }) => {
  // const { socialfeed } = useSocialFeed()
  const socialfeed = false

  const {
    headline,
    teaser,
    instagramfeed_color: { value: color },
  } = content

  return (
    socialfeed && (
      <section
        className={cx(styles.instagramFeed, {
          'bg-white': color === 'white',
          'bg-yellow': color === 'yellow',
          'bg-purple': color === 'blue',
        })}
      >
        <div className={styles.texts}>
          <div
            className={cx(styles.headline, {
              'text-white': color === 'yellow' || color === 'blue',
            })}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
          <div
            className={cx(styles.teaser, {
              'text-white': color === 'yellow' || color === 'blue',
            })}
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
        </div>
        <div className={styles.posts}>
          {socialfeed.map((post, i) => (
            <a
              key={i}
              href={post.permalink}
              target="_blank"
              rel="nofollow noreferrer"
              className={styles.post}
            >
              <Image
                src={
                  post.media_type === 'VIDEO'
                    ? post.thumbnail_url
                    : post.media_url
                }
                proxyimage={'true'}
                layout={'fill'}
                quality={80}
                objectFit="cover"
                alt={post.headline}
              ></Image>
              <span className={styles.icon}>
                {post.media_type === 'CAROUSEL_ALBUM' ? (
                  <Reel className={styles.reel} />
                ) : post.media_type === 'VIDEO' ? (
                  <Video className={styles.video} />
                ) : null}
              </span>
            </a>
          ))}
        </div>
      </section>
    )
  )
}

export default InstagramFeed
