import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { SiteInformationContext } from '../context/site-information-context'
import styles from '../styles/Footer.module.scss'
import LanguageSwitcher from './LanguageSwitcher'
import { useJobPublications } from '../lib/data/use-jobs'
import cx from 'classnames'
import TextMedia from './Blocks/TextMedia'
import ImageComponent from './Image'
import Image from './Image'

export const Footer = ({ withCareer = false, pageInformation = {} }) => {
  const { t, lang } = useTranslation('common')

  const siteInformation = useContext(SiteInformationContext)
  const [socialmediaLinks, setSocialmediaLinks] = useState([])

  useEffect(() => {
    setSocialmediaLinks(
      Object.entries(siteInformation?.socialmedia || {})
        ?.map(([key, url]) => {
          const platform = key.split('_')?.[1]
          return {
            platform,
            icon: `/images/icons/${platform}.svg`,
            url,
          }
        })
        .filter(x => x)
        .filter(x => !!x.url)
        .reverse()
    )
  }, [siteInformation])

  const currentYear = new Date().getFullYear()

  const { jobs: jobPublications } = useJobPublications(lang, {
    revalidateOnMount: true,
  })

  return (
    <footer className={styles.footer}>
      {withCareer && siteInformation?.footer_career && (
        <div className={cx(styles.careerWrapper, 'careerWrapper')}>
          <TextMedia
            isSection={false}
            content={siteInformation.footer_career}
          />
          <div className={styles.hr} />
        </div>
      )}

      <div
        className={cx('container mx-auto flex lg:hidden lg:pt-16', {
          'pt-8': !withCareer,
          'pt-0': withCareer,
        })}
      >
        <LanguageSwitcher
          className="place-content-end"
          pageInformation={pageInformation}
        />
      </div>

      <div className={styles.sectionTop}>
        {siteInformation &&
          siteInformation.footer_navigation?.tree?.map(navEntry => (
            <div key={navEntry.page.id} className={styles.linkSection}>
              <div className={styles.title}>{navEntry.page.title}</div>
              <div className={styles.navItems}>
                {navEntry.children.map(child1 => (
                  <div className={styles.navItem} key={child1.page.id}>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={child1.page.url}
                      target={
                        child1.page?.target_blank ||
                        child1.page?.extern_target_blank
                          ? '_blank'
                          : '_self'
                      }
                      rel={
                        child1.page?.target_blank ||
                        child1.page?.extern_target_blank
                          ? 'noreferrer nofollow'
                          : ''
                      }
                    >
                      {child1.page.title}
                      {child1.page.showopenjobs &&
                      jobPublications &&
                      jobPublications.length > 0 ? (
                        <span className={'openJobsBadge light ml-2'}>
                          {jobPublications.length}
                        </span>
                      ) : (
                        ''
                      )}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        <div
          className={cx(
            styles.linkSection,
            'mt-16 sm:mt-0 sm:self-end lg:self-auto'
          )}
        >
          <div className="min-h-full flex flex-col justify-between">
            <div className={'lg:text-right'}>
              {socialmediaLinks?.map(({ platform, icon, url }) => (
                <a
                  key={platform}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialmediaLink}
                >
                  <div className={'sr-only'}>{platform}</div>
                  <ImageComponent
                    src={icon}
                    layout={'fixed'}
                    height={25}
                    width={25}
                    quality={80}
                    objectFit="contain"
                    alt={platform}
                  />
                </a>
              ))}
            </div>
            <div className="hidden lg:flex place-self-end">
              <LanguageSwitcher
                className="place-content-end"
                pageInformation={pageInformation}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionBottom}>
        <div className={styles.sectionBottomInner}>
          <div className={styles.hr} />

          <div className={styles.bottomContent}>
            <div className={styles.logoSection}>
              <Image
                src={'/svg/paymenttools_logo_yellow_white.svg'}
                alt={'paymenttools logo'}
                width={202}
                height={26}
              />
              <Image
                src={'/logos/PJR27001.png'}
                alt={'PJR27001'}
                width={40}
                height={26}
              />
            </div>
            <div className={styles.links}>
              <a href={'/impressum'}>{t('imprint')}</a>
              <div className={styles.separator}>•</div>
              <a href={'/datenschutz'}>{t('privacy')}</a>
              <div className={styles.separator}>•</div>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  // eslint-disable-next-line no-undef
                  if (UC_UI) UC_UI.showSecondLayer()
                }}
              >
                {t('privacy_settings')}
              </a>
            </div>
            <div className={styles.companyInformation}>
              &copy; {currentYear} Paymenttools
              <span className="ml-2 opacity-60 text-14-24">
                v{require('../package.json').version}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
